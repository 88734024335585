export default defineNuxtRouteMiddleware((_to, _from) => {
  const { org } = useUser()

  const config = useRuntimeConfig()

  if (config.public.env !== 'development') {
    if (!org.value) {
      return navigateTo('/home')
    }

    return navigateTo(`/org/${org.value.id}/home`)
  }
})
